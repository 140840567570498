<template>
  <main class="`p-contact layout--contact`">
    <AppPageHeader title="Get in touch" headerText="208" headerImg="https://mm-aws-s3-media.s3.ap-southeast-2.amazonaws.com/large_ROD_5681_9e43064ad9.jpg" />

    <section class="section__contact">
        <FormEnquire />

        <article>
          <h3>Reach Us</h3>
          <p>208-210 McIntyre Rd,
            Sunshine, 3020</p>

          <p>(03) 9356 4202</p>

          <a style="margin-bottom: 0.5em; display: block;" href="mailto:sales@melbournemalibu.com.au">Email Sales</a>
          <a style="margin-bottom: 0.5em; display: block;" href="mailto:service@melbournemalibu.com.au">Email Servicing</a>
          <a href="mailto:parts@melbournemalibu.com.au">Email Parts</a>
        </article>

        <article>
          <h3>Showroom Hours</h3>
          <p>Monday - Friday: 9am - 5pm<br />
          Saturday: 9am - 3pm
          </p>

          <h3>Service Hours</h3>
          <p>Monday - Friday: 9am - 5pm</p>
        </article>
    </section>

    <section class="section__map">
      <MapboxMap
        style="height: 100%"
        map-style="mapbox://styles/snyde/clas2fb40000014r0995mts7k"
        access-token="pk.eyJ1Ijoic255ZGUiLCJhIjoiY2o1Y2RrZHU2MGFpODMybzY1MGMyc2V3ciJ9.o0E6e5pjDFT32WHHxaIUfg"
        :center="[lng, lat]"
        :zoom="15">
        <MapboxMarker color="var(--color-primary)" :lng-lat="[lng, lat]" />
      </MapboxMap>
    </section>
  </main>
</template>

<script setup>
import 'mapbox-gl/dist/mapbox-gl.css'
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl'
import FormEnquire from '@/components/FormEnquire'

const lng = 144.8330078
const lat = -37.7555885
</script>
